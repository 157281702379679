import React from "react";
import { StyleSheet, View } from "react-native";

import {
  LocalizedLink,
  useAuth,
  useContentResource,
  useFeatureControl,
} from "@ctv/core";
import { Image, Text, Token } from "@ctv/momentum";
import { formatMessage } from "@ctv/shared/utils/intl";
import Hours24 from "@traveloka/icon-kit-web/react/IcContact24Hours";

function Footer() {
  const contactUsFC = useFeatureControl("b2b-contact-us");
  const { user } = useAuth();
  const content = useContentResource().CorporateFooter;

  const hasContactUs = contactUsFC.enabled && user;

  return (
    <View style={Style.wrapper}>
      <View style={Style.content}>
        <View style={Style.topContent}>
          <View style={Style.leftContainer}>
            <Image
              style={Style.image}
              alt="traveloka for corporates"
              height={30}
              src={"/images/traveloka-for-corporates-white.png"}
            />
            <View style={Style.contactUsContainer}>
              <Hours24
                color={Token.color.lightPrimary}
                height={40}
                width={40}
              />
              <View style={Style.contactUsLabel}>
                <Text variant="ui-tiny" ink="black-muted">
                  {content.contactUsText}
                </Text>
                <Text style={Style.weightMedium} ink="white-primary">
                  {content.contactUsValue}
                </Text>
              </View>
            </View>
          </View>
          {hasContactUs && (
            <View style={Style.container}>
              <>
                <Text style={Style.weightRegular} ink="white-primary">
                  {content.travelokaBusinessTitle}
                </Text>
                <LocalizedLink href="/contact-us">
                  <Text variant="ui-tiny" ink="black-secondary">
                    {content.redirectContactUsText}
                  </Text>
                </LocalizedLink>
              </>
            </View>
          )}
          <View style={Style.container}>
            <>
              <Text style={Style.weightRegular} ink="white-primary">
                {content.othersTitle}
              </Text>
              <LocalizedLink href="/terms-and-conditions" target="_blank">
                <Text variant="ui-tiny" ink="black-secondary">
                  {content.redirectTncText}
                </Text>
              </LocalizedLink>
            </>
          </View>
        </View>
        <View style={Style.copyright}>
          <Text variant="ui-small" ink="black-muted">
            {formatMessage(content.copyrightText, {
              year: new Date().getFullYear(),
            })}
          </Text>
        </View>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  wrapper: {
    marginTop: "auto",
    backgroundColor: Token.color.darkPrimary,
    alignItems: "center",
  },
  content: {
    width: "100%",
    marginHorizontal: "auto",
    maxWidth: 1260,
    paddingHorizontal: Token.spacing.l,
  },
  topContent: {
    width: "100%",
    paddingVertical: Token.spacing.xxl,
    flexDirection: "row",
  },
  leftContainer: {
    flex: 2,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  container: {
    flex: 1,
  },
  image: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  rightDivider: {
    borderRightWidth: 1,
    borderColor: Token.color.lightPrimary,
    paddingRight: Token.spacing.xs,
    marginRight: Token.spacing.xs,
  },
  contactUsContainer: {
    marginTop: Token.spacing.s,
    marginLeft: Token.spacing.l,
    flexDirection: "row",
  },
  contactUsLabel: {
    marginLeft: Token.spacing.xs,
  },
  weightMedium: { fontWeight: Token.typography.uiBaseline.fontWeight },
  weightRegular: { fontWeight: Token.typography.uiBaselineBold.fontWeight },
  copyright: {
    width: "100%",
    alignItems: "center",
    paddingVertical: Token.spacing.xxxxl,
    borderTopColor: Token.color.borderDivide,
    borderTopWidth: Token.borderWidth.thin,
  },
});

export default Footer;
