import React, { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import { Token } from "@ctv/momentum";

type Props = PropsWithChildren<{
  light?: boolean;
  covidInfo?: boolean;
}>;

export default function Page(props: Props) {
  const { children } = props;

  return <View style={Style.container}>{children}</View>;
}

const Style = StyleSheet.create({
  container: {
    // @ts-ignore
    minHeight: "100vh",
    minWidth: 1080,
    backgroundColor: Token.color.lightPrimary,
  },
});
