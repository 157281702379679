"use client";
import { FC, MutableRefObject, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import {
  Permission,
  useAuth,
  useContentResource,
  useLocale,
  useLocalizedRouterServer,
} from "@ctv/core";
import {
  Badge,
  Button,
  Card,
  Text,
  Token,
  View,
  useHover,
} from "@ctv/momentum";
import Coachmark from "@ctv/momentum/Coachmark/Coachmark";
import manager from "@ctv/momentum/Modal/ModalManager";
import { HoverConfig } from "@ctv/momentum/hooks/useHover";
import {
  firstPicCoachmarkKey,
  useLocalStorageUserLevel,
} from "@ctv/shared/hooks/useLocalStorageUserLevel";
import { IconKitProps } from "@ctv/shared/types/icon-kit";

import BriefCase from "@traveloka/icon-kit-web/react/IcLinkToCareersFill16";
import User from "@traveloka/icon-kit-web/react/IcUserAccountFill";

const COACHMARK_WIDTH = 700;
const COACHMARK_HEIGHT = 456;

type Props = {
  target: [
    MutableRefObject<HTMLElement | null>,
    MutableRefObject<HTMLElement | null>
  ];
};
export default function FirstPicCoachmark(props: Props) {
  const { target } = props;

  const [visible, setVisible] = useState(false);
  const [activeTarget, setActiveTarget] = useState<number | null>(null);
  const [companyHovered, companyHandlers] = useHover();
  const [profileHovered, profileHandlers] = useHover();
  const router = useLocalizedRouterServer();
  const locale = useLocale();
  const user = useAuth().user!;
  const { get, set } = useLocalStorageUserLevel(firstPicCoachmarkKey);

  const cr = useContentResource().CorporateManagementIntroCoachmark;

  useEffect(() => {
    const hasSeenIntroCoachmark = get();
    if (
      !hasSeenIntroCoachmark &&
      `/${locale}/home` === window.location.pathname.replace(/\/$/, "")
    ) {
      manager.add(firstPicCoachmarkKey, setVisible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  useEffect(() => {
    if (companyHovered) {
      setActiveTarget(0);
      return;
    }
    setActiveTarget(null);
  }, [companyHovered]);

  useEffect(() => {
    if (profileHovered) {
      setActiveTarget(1);
      return;
    }
    setActiveTarget(null);
  }, [profileHovered]);

  function onClose() {
    manager.remove(firstPicCoachmarkKey);
    set();
  }

  function redirectToCompanySettings() {
    let url = "/company/info";

    // Company Settings
    const hasUserRole = user.has(
      Permission.USER_ROLE_READ,
      Permission.USER_ROLE_CREATE,
      Permission.USER_ROLE_UPDATE,
      Permission.USER_ROLE_DELETE
    );
    const hasPolicy = user.has(
      Permission.POLICY_READ,
      Permission.POLICY_CREATE,
      Permission.POLICY_UPDATE,
      Permission.POLICY_DELETE
    );
    const hasApprovalSystemConfig = user.has(
      Permission.APPROVAL_CONFIG_VIEW,
      Permission.APPROVAL_CONFIG_MODIFY
    );

    // Company Structure
    const hasDivision = user.has(
      Permission.DIVISION_READ,
      Permission.DIVISION_CREATE,
      Permission.DIVISION_UPDATE,
      Permission.DIVISION_DELETE
    );
    const hasTier = user.has(
      Permission.TIER_READ,
      Permission.TIER_CREATE,
      Permission.TIER_UPDATE,
      Permission.TIER_DELETE
    );
    const hasEmployee = user.has(
      Permission.USER_READ,
      Permission.USER_CREATE,
      Permission.USER_UPDATE,
      Permission.USER_DELETE
    );

    if (hasUserRole || hasPolicy || hasApprovalSystemConfig) {
      url = "/company/permissions";
    } else if (hasEmployee || hasDivision || hasTier) {
      url = "/company/people-structure";
    }

    router.push(url);
    set();
  }

  function redirectToProfile() {
    router.push("/profile/edit");
    set();
  }

  const currentTarget =
    activeTarget === null ? undefined : target[activeTarget];

  return (
    <Coachmark
      content={
        <Card style={styles.container}>
          <View spacing="xxl">
            <View spacing="s" align="center">
              <Text variant="title-2" ink="black-secondary">
                {cr.firstPicHeaderText}
              </Text>
              <Text variant="headline">{cr.firstPicTitle}</Text>
            </View>
            <View row spacing="l">
              <CardSelection
                Icon={BriefCase}
                description={cr.firstPicExploreCompanySettingsDescription}
                title={cr.firstPicExploreCompanySettingsTitle}
                hovered={companyHovered}
                hoverHandlers={companyHandlers}
                recommended
                onPress={redirectToCompanySettings}
              />
              <CardSelection
                Icon={User}
                description={cr.firstPicSetupProfileDescription}
                title={cr.firstPicSetupProfileTitle}
                hovered={profileHovered}
                hoverHandlers={profileHandlers}
                onPress={redirectToProfile}
              />
            </View>
            <Button
              variant="text-primary"
              text={cr.firstPicCloseButton}
              onPress={onClose}
            />
          </View>
        </Card>
      }
      target={currentTarget}
      highlightTarget={currentTarget}
      visible={visible}
      fixatedContent={{
        // to cater mweb view
        topSpace:
          Math.min(window.screen.height, window.innerHeight) / 2 -
          COACHMARK_HEIGHT / 2,
        leftSpace: window.innerWidth / 2 - COACHMARK_WIDTH / 2,
        height: COACHMARK_HEIGHT,
        width: COACHMARK_WIDTH,
        rightSpace: 0,
        bottomSpace: 0,
      }}
      scrollToHighlight={false}
    />
  );
}

type CardSelectionProps = {
  Icon: FC<IconKitProps>;
  title: string;
  description: string;
  hovered: boolean;
  hoverHandlers: HoverConfig<unknown>;
  recommended?: boolean;
  onPress(): void;
};
function CardSelection(props: CardSelectionProps) {
  const {
    Icon,
    description,
    title,
    hovered,
    hoverHandlers,
    recommended,
    onPress,
  } = props;

  const cr = useContentResource().CorporateManagementIntroCoachmark;

  return (
    <TouchableOpacity onPress={onPress}>
      <Card
        style={styles.cardSelectionContainer}
        align="center"
        spacing="m"
        elevation={hovered ? "float" : undefined}
        {...hoverHandlers}
      >
        {recommended && (
          <Badge
            style={styles.recommendBadge}
            text={cr.recommendedBadge}
            variant="info"
          />
        )}
        <View style={styles.icon}>
          <Icon color={Token.color.lightPrimary} width={28} height={28} />
        </View>
        <Text variant="title-2" ink="interactive">
          {title}
        </Text>
        <Text
          style={styles.description}
          variant="ui-small"
          ink="black-secondary"
        >
          {description}
        </Text>
      </Card>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: Token.spacing.l,
    paddingBottom: Token.spacing.xl,
  },
  cardSelectionContainer: {
    flex: 1,
    position: "relative",
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.l,
    width: 314,
    transition: `${Token.timing.instant}ms box-shadow ease-in-out`,
  },
  icon: {
    borderRadius: Token.borderRadius.rounded,
    backgroundColor: Token.color.godwitBlue,
    padding: Token.spacing.m,
  },
  recommendBadge: {
    position: "absolute",
    top: -Token.spacing.xs,
    marginHorizontal: "auto",
  },
  dummyCoachmarkRef: {
    opacity: 0,
    position: "absolute",
    top: 0,
    right: -100,
  },
  description: {
    textAlign: "center",
  },
});
