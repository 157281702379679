import React, { PropsWithChildren } from "react";

import { ViewSeo } from "@ctv/momentum";
import cssStyles from "./Page.module.css";

type Props = PropsWithChildren<{
  light?: boolean;
  covidInfo?: boolean;
}>;

export default function Page(props: Props) {
  const { children } = props;

  return <ViewSeo className={cssStyles.container}>{children}</ViewSeo>;
}
