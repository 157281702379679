import { CreditLimitStatus } from "@ctv/core/providers/credit-limit/types";
import { Token } from "@ctv/momentum";

export function creditLimitColorSwitcher(
  status: CreditLimitStatus | undefined
) {
  switch (status) {
    case "EMPTY":
    case "MINUS":
      return Token.color.darkSecondary;
    case "VERY_LOW":
      return Token.color.redPrimary;
    case "LOW":
      return Token.color.yellowPrimary;
    case "SUFFICIENT":
      return Token.color.greenPrimary;
    default:
      return Token.color.lightStain;
  }
}

export function creditLimitPercentageInkSwitcher(status: CreditLimitStatus) {
  switch (status) {
    case "EMPTY":
    case "SUFFICIENT":
    case "VERY_LOW":
    case "MINUS":
      return {
        color: Token.color.lightPrimary,
      };
    case "LOW":
      return {
        color: Token.color.darkPrimary,
      };
  }
}
