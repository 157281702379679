import React, { ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import {
  LocalizedLink,
  PaymentMethod,
  Permission,
  useAuth,
  useContentResource,
  useLocale,
} from "@ctv/core";
import MenuCreditLimit from "@ctv/core/providers/credit-limit/components/MenuCreditLimit";
import { Text, Token } from "@ctv/momentum";
import { appendTestId } from "@ctv/shared/utils/TestUtil";

import Policy from "@traveloka/icon-kit-web/react/IcPolicyCheck";
import ProductBill from "@traveloka/icon-kit-web/react/IcProductDuotoneBill";
import Document from "@traveloka/icon-kit-web/react/IcSystemDocument";
import Info from "@traveloka/icon-kit-web/react/IcSystemStatusInfo";
import Booking from "@traveloka/icon-kit-web/react/IcUserBooking";
import Employee from "@traveloka/icon-kit-web/react/IcUserQuickPick";
import Receipt from "@traveloka/icon-kit-web/react/IcUserReceipt";

import MenuItem from "../../MenuItem/MenuItem";

type Props = {
  currentPath?: string;
  headerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

type Menu = {
  text: string;
  LeftIcon: ReactNode;
  LeftIconActive: ReactNode;
  url: string;
  name: string;
};

export default function CompanyMenu(props: Props) {
  const { currentPath = "", headerStyle, style, testID } = props;

  const content = useContentResource().CorporateMenu;
  const locale = useLocale();

  const { user } = useAuth();

  const menus: Menu[] = [
    {
      text: content.companyInfoMenuText,
      LeftIcon: <Info color={Token.color.bluePrimary} />,
      LeftIconActive: <Info color={Token.color.lightPrimary} />,
      url: "/company/info",
      name: "company-info",
    },
    {
      text: content.purchaseListMenuText,
      LeftIcon: <Receipt color={Token.color.bluePrimary} />,
      LeftIconActive: <Receipt color={Token.color.lightPrimary} />,
      url: "/booking/?tab=my-purchase",
      name: "purchase-list",
    },
    {
      text: content.bookingListMenuText,
      LeftIcon: <Booking color={Token.color.bluePrimary} />,
      LeftIconActive: <Booking color={Token.color.lightPrimary} />,
      url: "/booking/?tab=my-booking",
      name: "my-booking",
    },
  ];

  if (user) {
    if (user.globalTripRequestApproval) {
      menus.splice(-2, 0, {
        text: content.approvalListMenuText,
        LeftIcon: <Policy color={Token.color.bluePrimary} />,
        LeftIconActive: <Policy color={Token.color.lightPrimary} />,
        url: "/approval",
        name: "approval",
      });
    }

    if (
      user.has(
        Permission.USER_READ,
        Permission.USER_CREATE,
        Permission.USER_UPDATE,
        Permission.USER_DELETE
      )
    ) {
      menus.splice(-2, 0, {
        text: content.employeeListMenuText,
        LeftIcon: <Employee color={Token.color.bluePrimary} />,
        LeftIconActive: <Employee color={Token.color.lightPrimary} />,
        url: "/people-structure/?tab=employee",
        name: "employee-list",
      });
    }

    if (user.has(Permission.EXPENSE_REPORT_VIEW)) {
      menus.push({
        text: content.expenseReportMenuText,
        LeftIcon: <Document color={Token.color.bluePrimary} />,
        LeftIconActive: <Document color={Token.color.lightPrimary} />,
        url: "/financial/?tab=expense-report",
        name: "expense-report",
      });
    }

    if (user.has(Permission.INVOICE_LIST_VIEW)) {
      menus.push({
        text: content.invoiceListMenuText,
        LeftIcon: <ProductBill color={Token.color.bluePrimary} />,
        LeftIconActive: <ProductBill color={Token.color.lightPrimary} />,
        url: "/financial/?tab=invoice",
        name: "invoice-list",
      });
    }
  }

  const useInvoice = user?.paymentMethod === PaymentMethod.INVOICE;

  return (
    <View style={[Style.dropdownCompanyMenu, style]}>
      <View style={headerStyle}>
        <Text style={[Style.header]}>{content.title}</Text>
        {useInvoice && <MenuCreditLimit testID={testID} />}
      </View>
      {menus.map((menu) => (
        <LocalizedLink key={menu.name} href={menu.url}>
          <MenuItem
            {...menu}
            style={Style.menuItem}
            testID={appendTestId(testID, `menu.company.${menu.name}`)}
            key={menu.url}
            isActive={currentPath.startsWith("/" + locale + menu.url)}
          />
        </LocalizedLink>
      ))}
    </View>
  );
}

const Style = StyleSheet.create({
  dropdownCompanyMenu: {
    minWidth: 256,
    paddingVertical: Token.spacing.xs,
  },
  header: {
    fontWeight: Token.typography.uiBaselineBold.fontWeight,
    letterSpacing: 1,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
  },
  menuItem: {
    marginRight: 0,
  },
});
