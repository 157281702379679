import React, { ReactNode } from "react";
import { StyleSheet, View, StyleProp, ViewStyle } from "react-native";

import { useContentResource, LocalizedLink } from "@ctv/core";
import Account from "@traveloka/icon-kit-web/react/IcUserAccount";
import Employement from "@traveloka/icon-kit-web/react/IcUserMyBill";
import { Text, Token } from "@ctv/momentum";
import MenuItem from "../../MenuItem/MenuItem";
import { appendTestId } from "@ctv/shared/utils/TestUtil";

type Props = {
  testID?: string;
  currentPath?: string;
  headerStyle?: StyleProp<ViewStyle>;
  children?: ReactNode;
};

export default function ProfileMenu(props: Props) {
  const { testID, currentPath, headerStyle, children } = props;

  const content = useContentResource().CorporateProfileSidebar;

  const menus = [
    {
      text: content.editProfileText,
      LeftIcon: <Account color={Token.color.bluePrimary} />,
      LeftIconActive: <Account color={Token.color.lightPrimary} />,
      url: "/profile/edit",
      name: "edit-profile",
    },
    {
      text: content.employmentDetailText,
      LeftIcon: <Employement color={Token.color.bluePrimary} />,
      LeftIconActive: <Employement color={Token.color.lightPrimary} />,
      url: "/profile/employment-detail",
      name: "employment-detail",
    },
  ];

  return (
    <View style={Style.dropdownProfileMenu}>
      <View style={headerStyle}>
        <Text style={Style.header}>{content.title}</Text>
      </View>
      {menus.map((menu) => (
        <LocalizedLink key={menu.name} href={menu.url}>
          <MenuItem
            {...menu}
            testID={appendTestId(testID, `menu.profile.${menu.name}`)}
            key={menu.url}
            isActive={menu.url === currentPath}
          />
        </LocalizedLink>
      ))}
      {children}
    </View>
  );
}

const Style = StyleSheet.create({
  dropdownProfileMenu: {
    minWidth: 200,
    paddingVertical: Token.spacing.xs,
  },
  header: {
    fontWeight: Token.typography.uiBaselineBold.fontWeight,
    letterSpacing: 1,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
  },
});
