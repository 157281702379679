import React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { Token, Text } from "@ctv/momentum";
import { CreditLimitStatus } from "@ctv/core/providers/credit-limit/types";
import { appendTestId } from "@ctv/shared/utils/TestUtil";
import {
  creditLimitColorSwitcher,
  creditLimitPercentageInkSwitcher,
} from "../../../_utils/credit-limit";

type Props = {
  style?: StyleProp<ViewStyle>;
  status: CreditLimitStatus;
  percentage: string;
  testID?: string;
};

function CreditLimitBadge(props: Props) {
  const { style, status, percentage, testID } = props;

  const color = {
    backgroundColor: creditLimitColorSwitcher(status),
  };

  const textInk = creditLimitPercentageInkSwitcher(status);

  return (
    <View
      testID={appendTestId(testID, `credit-limit.badge.${status}`)}
      style={[Style.badge, color, style]}
    >
      <Text style={textInk} variant="ui-tiny">
        {percentage}
      </Text>
    </View>
  );
}

const Style = StyleSheet.create({
  badge: {
    paddingVertical: Token.spacing.xxs,
    paddingHorizontal: Token.spacing.s,
    borderRadius: Token.borderRadius.rounded,
    // @ts-expect-error
    width: "fit-content",
  },
});

export default CreditLimitBadge;
