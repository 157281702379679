import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";

import CreditLimitBadge from "@ctv/app/[locale]/(company)/company/info/_components/legacy/(BillingInfo)/CreditLimitBadge";
import CreditLimitIcon from "@ctv/app/[locale]/(company)/company/info/_components/legacy/(BillingInfo)/CreditLimitIcon";
import { useContentResource } from "@ctv/core";
import { useAccountStatus } from "@ctv/core/providers/account-status/AccountStatusContext";
import { useCreditLimit } from "@ctv/core/providers/credit-limit/CreditLimitContext";
import { Token } from "@ctv/momentum";
import Chevron from "@traveloka/icon-kit-web/react/IcSystemChevronDown16";

export default function HeaderCreditLimit() {
  const { data: creditLimit, fetchCreditLimit } = useCreditLimit();
  const { isSuspended } = useAccountStatus();
  const accountStatusContent = useContentResource().CorporateAccountStatus;

  useEffect(() => {
    fetchCreditLimit();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  if (!creditLimit) {
    return (
      <View style={[Style.container, { alignItems: "flex-end" }]}>
        <CreditLimitIcon
          testID="header"
          style={Style.icon}
          status={undefined}
          size="tiny"
        />
        <Chevron color={Token.color.darkPrimary} />
      </View>
    );
  }

  if (creditLimit.hasPermission) {
    if (isSuspended) {
      return (
        <View style={Style.container}>
          <CreditLimitBadge
            testID="header"
            style={Style.badge}
            status={"EMPTY"}
            percentage={accountStatusContent.suspended}
          />
          <Chevron color={Token.color.darkPrimary} />
        </View>
      );
    }

    return (
      <View style={Style.container}>
        <CreditLimitBadge
          testID="header"
          style={Style.badge}
          status={creditLimit.remainingCreditLimit.status}
          percentage={creditLimit.remainingPercentage}
        />
        <Chevron color={Token.color.darkPrimary} />
      </View>
    );
  }

  if (isSuspended) {
    return (
      <View style={[Style.container, { alignItems: "flex-end" }]}>
        <CreditLimitIcon
          testID="header"
          style={Style.icon}
          status={"EMPTY"}
          size="tiny"
          isSuspended
        />
        <Chevron color={Token.color.darkPrimary} />
      </View>
    );
  }

  return (
    <View
      style={[
        Style.container,
        creditLimit.hasPermission
          ? { alignItems: "center" }
          : { alignItems: "flex-end" },
      ]}
    >
      <CreditLimitIcon
        style={Style.icon}
        status={creditLimit.remainingCreditLimit.status}
        size="tiny"
        testID="header"
      />
      <Chevron color={Token.color.darkPrimary} />
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  icon: {
    marginLeft: Token.spacing.l,
    marginRight: Token.spacing.s,
  },
  badge: {
    marginRight: Token.spacing.s,
  },
});
