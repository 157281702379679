import React, { PropsWithChildren } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { DotLoader, Token } from "@ctv/momentum";
import { useLocale } from "@ctv/core";

type Props = PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
  hasLocaleLoader?: boolean;
}>;

export default function Content(props: Props) {
  const { hasLocaleLoader = true, ...restProps } = props;

  return (
    <>
      {hasLocaleLoader && <LocaleOverlayLoader />}
      <View {...restProps} style={[Style.content, restProps.style]} />
    </>
  );
}

function LocaleOverlayLoader() {
  return (
    <View style={Style.localeOverlay}>
      <DotLoader />
    </View>
  );
}

const Style = StyleSheet.create({
  content: {
    zIndex: 1,
    width: 960,
    paddingTop: Token.spacing.xxl,
    paddingBottom: Token.spacing.m,
    marginHorizontal: "auto",
  },
  localeOverlay: {
    position: "absolute",
    zIndex: 2,
    height: "100%",
    width: "100%",
    display: "none",
    backgroundColor: Token.opacity.translucent(Token.color.darkPrimary),
    alignItems: "center",
    justifyContent: "center",
  },
});
